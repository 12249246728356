import './style.less'
import logoImg from '@/assets/img/logo.png'
import Navbar from '../Navbar'
import { App, Dropdown } from 'antd'
import {
  MenuUnfoldOutlined,
  SyncOutlined,
  LockOutlined,
  LogoutOutlined,
  UserOutlined,
  CaretDownOutlined,
  ExclamationCircleFilled,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import { SET_M_MENU_OPENED } from '@/store/actions'
import { useNavigate } from 'react-router-dom'
import { TOKEN_CACHE_KEY } from '@/config'

const dropDownMenus = [
  {
    label: (
      <div className="flex align-center">
        <LockOutlined />
        <span className="ml-8">修改密码</span>
      </div>
    ),
    key: 'modifyPwd',
  },
  {
    label: (
      <div className="flex align-center">
        <LogoutOutlined />
        <span className="ml-8">退出</span>
      </div>
    ),
    key: 'logout',
  }
]

const pageReload = () => {
  window.location.reload()
}

function Header ({ isM, userInfo, setMMenuOpened, resetUserInfo, resetMenu }) {
  const { modal } = App.useApp()
  const navigate = useNavigate()

  const RightTopPanePc = () => (
    <div className="layout-header-right-pc">
      <SyncOutlined
        style={{
          marginRight: 20,
          fontSize: 18,
          cursor: 'pointer'
        }}
        onClick={pageReload}
      />
      <Dropdown
        menu={{
          items: dropDownMenus,
          onClick: clickDropDownMenu
        }}
      >
        <div className="flex align-center font-12">
          <UserOutlined
            style={{
              marginRight: 5,
              fontSize: 22
            }}
          />
          <span>欢迎，{userInfo.account}</span>
          <CaretDownOutlined />
        </div>
      </Dropdown>
    </div>
  )

  const RightTopPaneM = () => (
    <div className="layout-header-right-m">
      <div>欢迎，{userInfo.account}</div>
      <div className="logout">
        <LogoutOutlined />
        <span onClick={logout}>退出</span>
      </div>
    </div>
  )

  function clickDropDownMenu ({ key }) {
    switch (key) {
      case 'modifyPwd':
        navigate('/layout/setting/password')
        break
      case 'logout':
        logout()
        break
      default:
        break
    }
  }

  function logout () {
    modal.confirm({
      title: '提示',
      content: '您确认要退出系统吗？',
      icon: <ExclamationCircleFilled />,
      onOk() {
        resetUserInfo()
        resetMenu()
        localStorage.removeItem(TOKEN_CACHE_KEY)
        navigate('/login', { replace: true })
      },
      onCancel() {
        console.log('cancel')
      }
    })
  }

  return (
    <div className="layout-header">
      {
        isM &&
        <div className="m-menu-trigger" onClick={() => setMMenuOpened(true)}>
          <MenuUnfoldOutlined
            style={{
              color: '#ffffff',
              fontSize: 24
            }}
          />
        </div>
      }

      <div
        className={isM ? 'logo-wrap m' : 'logo-wrap'}
      >
        <img className="logo" src={logoImg} alt=""/>
        <h1 className="app-name">数聚生活</h1>
      </div>

      {!isM && <Navbar />}

      {isM ? <RightTopPaneM /> : <RightTopPanePc />}
    </div>
  )
}

const mapStateToProps = state => {
  return {
    isM: state.windowSize === 's',
    userInfo: state.userInfo || {}
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setMMenuOpened: (opened) => dispatch(SET_M_MENU_OPENED(opened)),
    resetUserInfo: () => dispatch({ type: 'SET_USER_INFO', data: null }),
    resetMenu: () => dispatch({ type: 'SET_MENU', data: [] })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
