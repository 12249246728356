const initialState = {
  // 窗口尺寸 s/m/l 小屏/中屏/大屏
  windowSize: 'l',
  // 登录用户信息的
  userInfo: null,
  // 菜单
  menu: [],
  // 导航条菜单
  navMenus: [],
  // 移动端菜单是否展开
  mMenuOpened: false
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_WINDOW_SIZE':
      return Object.assign({}, state, {
        windowSize: action.size
      })
    case 'SET_USER_INFO':
      return Object.assign({}, state, {
        userInfo: action.data
      })
    case 'SET_MENU':
      return Object.assign({}, state, {
        menu: action.data
      })
    case 'SET_NAV_MENUS':
      return Object.assign({}, state, {
        navMenus: action.data
      })
    case 'ADD_NAV_MENU':
      const _navMenus = JSON.parse(JSON.stringify(state.navMenus))
      if (!_navMenus.some(e => e.path === action.data.path)) {
        _navMenus.push(action.data)
      }
      sessionStorage.setItem('navMenus', JSON.stringify(_navMenus))
      return Object.assign({}, state, {
        navMenus: _navMenus
      })
    case 'REMOVE_NAV_MENU':
      const __navMenus = state.navMenus.filter(e => e.path !== action.path)
      sessionStorage.setItem('navMenus', JSON.stringify(__navMenus))
      return Object.assign({}, state, {
        navMenus: __navMenus
      })
    case 'SET_M_MENU_OPENED':
      return Object.assign({}, state, {
        mMenuOpened: action.opened
      })
    default:
      return state
  }
}

export default reducer
