import { WINDOW_SIZE_SMALL, WINDOW_SIZE_LARGE, DEFAULT_ROUTE_PATH } from '@/config'
import { getUserInfo, getMenu } from '@/api'
import { HomeOutlined } from '@ant-design/icons'

export function SET_WINDOW_SIZE () {
  let size
  const w = window.innerWidth
  if (w <= WINDOW_SIZE_SMALL) {
    size = 's'
  } else if (w >= WINDOW_SIZE_LARGE) {
    size = 'l'
  } else {
    size = 'm'
  }
  return {
    type: 'SET_WINDOW_SIZE',
    size
  }
}

export function GET_USER_INFO () {
  return dispatch => {
    getUserInfo().then(res => {
      dispatch({
        type: 'SET_USER_INFO',
        data: res.data
      })
    })
  }
}

function filterMenu (menuTree) {
  if (!menuTree || !menuTree.length) return []
  return menuTree.map(e => ({
    label: e.title,
    key: e.layout || e.name,
    children: e.sub && e.sub.length ? filterMenu(e.sub) : undefined
  }))
}

export function GET_MENU () {
  return dispatch => {
    getMenu().then(res => {
      dispatch({
        type: 'SET_MENU',
        data: [
          {
            label: <div className="text-center"><HomeOutlined /></div>,
            key: DEFAULT_ROUTE_PATH
          },
          ...filterMenu(res.data)
        ]
      })
    })
  }
}

export function SET_NAV_MENUS (data) {
  return {
    type: 'SET_NAV_MENUS',
    data
  }
}

export function ADD_NAV_MENU (data) {
  return {
    type: 'ADD_NAV_MENU',
    data
  }
}

export function REMOVE_NAV_MENU (path) {
  return {
    type: 'REMOVE_NAV_MENU',
    path
  }
}

export function SET_M_MENU_OPENED (opened) {
  return {
    type: 'SET_M_MENU_OPENED',
    opened
  }
}
