import request from './request'

// 登录  测试账号 17600185197 a11111111
export function login (data) {
  return request({
    url: '/v1/life/user/login',
    method: 'post',
    data
  })
}

// 获取用户信息
export function getUserInfo () {
  return request({
    url: '/v1/life/user/info'
  })
}

// 获取菜单
export function getMenu () {
  return request({
    url: '/v1/life/menu'
  })
}

// 修改密码
export function modifyPassword (data) {
  return request({
    url: '/v1/life/user/password',
    method: 'post',
    data
  })
}

// 代理商管理-代理商列表
export function getAgentList (params) {
  return request({
    url: '/v1/life/user/list',
    params
  })
}

// 代理商管理-代理商详情
export function getAgentInfo (id) {
  return request({
    url: `/v1/life/user/info?userId=${id}`
  })
}

// 代理商管理-新增代理商
export function addAgent (data) {
  return request({
    url: '/v1/life/user/create',
    method: 'post',
    data
  })
}

// 话费充值-产品列表
export function getProductListOfHf (params) {
  return request({
    url: '/v1/life/huaf/specs',
    params
  })
}

// 话费充值-订单列表
export function getOrderListOfHf (params) {
  return request({
    url: '/v1/life/huaf/list',
    params
  })
}

// 电费充值-产品列表
export function getProductListOfPower (params) {
  return request({
    url: '/v1/life/power/specs',
    params
  })
}

// 电费充值-订单列表
export function getOrderListOfPower (params) {
  return request({
    url: '/v1/life/power/list',
    params
  })
}

// 财务管理-我的账户信息
export function getMyAccountInfo () {
  return request({
    url: '/v1/life/asset/info'
  })
}

// 财务管理-账单明细
export function getFinanceBill (params) {
  return request({
    url: '/v1/life/bill',
    params
  })
}

// 财务管理-账单类型
export function getBillTypes () {
  return request({
    url: '/v1/life/bill/types'
  })
}

// 财务管理-佣金转化余额
export function convert (data) {
  return request({
    url: '/v1/life/convert',
    method: 'post',
    data
  })
}

// 财务管理-提现
export function withdraw (data) {
  return request({
    url: '/v1/life/withdraw',
    method: 'post',
    data
  })
}

// 财务管理-提现记录
export function getWithdrawRecord (params) {
  return request({
    url: '/v1/life/withdraw/record',
    params
  })
}

// 首页统计信息
export function statistic () {
  return request({
    url: '/v1/life'
  })
}

// 开发配置信息
export function getDevConfigInfo () {
  return request({
    url: '/v1/life/develop'
  })
}

// 修改开发配置信息
export function saveDevConfigInfo (data) {
  return request({
    url: '/v1/life/develop',
    method: 'post',
    data
  })
}

// 自助充值类型选择
export function selfServiceDepositTypes () {
  return request({
    url: '/v1/life/huaf/deposit/type'
  })
}

// 自助充值
export function selfServiceDeposit (data) {
  return request({
    url: '/v1/life/huaf/deposit',
    method: 'post',
    data
  })
}

// 地区数据
export function getArea () {
  return request({
    url: '/v1/life/power/area'
  })
}

// 电费充值类型
export function getPowerDepositTypes () {
  return request({
    url: '/v1/life/power/deposit/type'
  })
}

// 电费自助充值
export function depositPower (data) {
  return request({
    url: '/v1/life/power/deposit',
    method: 'post',
    data
  })
}
