import { ConfigProvider, App as AntdAppComponent } from 'antd'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import { MAIN_COLOR } from '@/config'
import { useEffect } from 'react'
import { connect } from 'react-redux'
import { SET_WINDOW_SIZE, GET_USER_INFO, GET_MENU, SET_NAV_MENUS } from './store/actions'
import router from './router'
import { RouterProvider } from 'react-router-dom'
import { TOKEN_CACHE_KEY, DEFAULT_ROUTE_PATH, DEFAULT_ROUTE_NAME } from '@/config'

dayjs.locale('zh')

function App(props) {

  useEffect(() => {
    props.setWindowSize()
    window.addEventListener('resize', props.setWindowSize)

    if (localStorage.getItem(TOKEN_CACHE_KEY)) {
      props.getUserInfo()
      props.getMenu()
    }

    if (sessionStorage.getItem('navMenus')) {
      props.setNavMenus(JSON.parse(sessionStorage.getItem('navMenus')))
    } else {
      props.setNavMenus([
        {
          path: DEFAULT_ROUTE_PATH,
          name: DEFAULT_ROUTE_NAME
        }
      ])
    }

    return () => {
      window.removeEventListener('resize', props.setWindowSize)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <ConfigProvider theme={{ token: { colorPrimary: MAIN_COLOR } }} locale={zhCN}>
      <AntdAppComponent>
        <RouterProvider router={router} />
      </AntdAppComponent>
    </ConfigProvider>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setWindowSize: () => dispatch(SET_WINDOW_SIZE()),
    getUserInfo: () => dispatch(GET_USER_INFO()),
    getMenu: () => dispatch(GET_MENU()),
    setNavMenus: (data) => dispatch(SET_NAV_MENUS(data))
  }
}

export default connect(null, mapDispatchToProps)(App)
