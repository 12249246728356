import { createBrowserRouter, Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { DEFAULT_ROUTE_PATH } from '@/config'
import Layout from '../layout/Index'
import AuthRouter from './AuthRouter'
import PageLoading from '../components/PageLoading'

const routes = [
  {
    path: '',
    component: () => <Navigate to={DEFAULT_ROUTE_PATH} />
  },
  {
    path: '/login',
    component: lazy(() => import('../pages/Login'))
  },
  {
    path: '/layout',
    component: Layout,
    children: [
      /** 首页 **/
      {
        path: 'home',
        component: lazy(() => import('../pages/Home'))
      },
      /** 设置-修改密码 **/
      {
        path: 'setting/password',
        component: lazy(() => import('../pages/Setting/ModifyPassword'))
      },
      /** 代理商管理-代理商列表 **/
      {
        path: 'user/userList',
        component: lazy(() => import('../pages/Agent/List'))
      },
      /** 代理商管理-添加代理商 **/
      {
        path: 'user/userAdd',
        component: lazy(() => import('../pages/Agent/Create'))
      },
      /** 代理商管理-编辑代理商 **/
      {
        path: 'user/userEdit',
        component: lazy(() => import('../pages/Agent/Edit'))
      },
      /** 话费充值-产品列表 **/
      {
        path: 'huaf/huafList',
        component: lazy(() => import('../pages/TelFee/ProductList'))
      },
      /** 话费充值-订单列表 **/
      {
        path: 'huaf/huafOrderList',
        component: lazy(() => import('../pages/TelFee/OrderList'))
      },
      /** 话费充值-自助充值 **/
      {
        path: 'huaf/huafDeposit',
        component: lazy(() => import('../pages/TelFee/SelfServiceDeposit'))
      },
      /** 电费充值-产品列表 **/
      {
        path: 'power/powerList',
        component: lazy(() => import('../pages/PowerFee/ProductList'))
      },
      /** 电费充值-订单列表 **/
      {
        path: 'power/powerOrderList',
        component: lazy(() => import('../pages/PowerFee/OrderList'))
      },
      /** 电费充值-自助充值 **/
      {
        path: 'huaf/powerDeposit',
        component: lazy(() => import('../pages/PowerFee/SelfServiceDeposit'))
      },
      /** 财务管理-我的账户 **/
      {
        path: 'finance/account',
        component: lazy(() => import('../pages/Finance/Account'))
      },
      /** 财务管理-账单明细 **/
      {
        path: 'finance/bill',
        component: lazy(() => import('../pages/Finance/Bill'))
      },
      /** 财务管理-提现记录 **/
      {
        path: 'finance/withdraw',
        component: lazy(() => import('../pages/Finance/WithdrawRecord'))
      },
      /** 开发配置 **/
      {
        path: 'develop',
        component: lazy(() => import('../pages/DevConfig'))
      }
    ]
  },
  {
    path: '*',
    component: () => <div>404 not found</div>
  }
]

function generateRoutes (routes) {
  return routes.map(e => {
    if (e.children) {
      e.children = generateRoutes(e.children)
    }
    e.element = (
      <AuthRouter>
        <Suspense fallback={<PageLoading />}>
          <e.component />
        </Suspense>
      </AuthRouter>
    )
    return e
  })
}

const router = createBrowserRouter(generateRoutes(routes))

export default router
