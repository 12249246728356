import './style.less'
import { LeftOutlined, RightOutlined, CloseOutlined } from '@ant-design/icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import { REMOVE_NAV_MENU } from '@/store/actions'
import { DEFAULT_ROUTE_PATH } from '@/config'
const classNames = require('classnames')

function Navbar ({ navMenus = [], removeNavMenu }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const navBarRef = useRef()
  const navListRef = useRef()
  const [navListTranslateX, setNavListTranslateX] = useState(0)
  const [paginationVisible, setPaginationVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('resize', resizeHandler)

    return () => {
      window.removeEventListener('resize', resizeHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    resizeHandler()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navMenus])

  const getNavBarWidth = () => {
    return navBarRef?.current?.clientWidth || 400
  }

  const resizeHandler = () => {
    setPaginationVisible(getNavBarWidth() < (navMenus.length + 1) * 100)
  }

  const clickHandler = (item) => {
    navigate(item.path)
  }

  const clickPrevHandler = () => {
    if (navListTranslateX < getNavBarWidth() - (navMenus.length + 1) * 100) return
    setNavListTranslateX(navListTranslateX - 100)
  }

  const clickNextHandler = () => {
    if (navListTranslateX === 0) return
    setNavListTranslateX(navListTranslateX + 100)
  }

  const closeHandler = (e, item) => {
    e.stopPropagation()
    const index = navMenus.findIndex(e => e.path === item.path)
    removeNavMenu(item.path)
    // 关闭的是当前页
    if (item.path === pathname) {
      const toPath = navMenus[index - 1]
        ? navMenus[index - 1].path
        : (navMenus[index + 1] ? navMenus[index + 1].path : '')
      navigate(toPath)
    }
  }

  return (
    <div className="navbar" ref={navBarRef}>
      {
        paginationVisible &&
        <div className="pagination prev" onClick={clickPrevHandler}>
          <LeftOutlined />
        </div>
      }

      <div style={{overflow: 'hidden'}}>
        <div
          className="nav-list"
          ref={navListRef}
          style={{transform: `translateX(${navListTranslateX}px)`}}
        >
          {
            navMenus.map(item => (
              <div
                className={classNames({
                  'nav': true,
                  'current': item.path === pathname,
                  'hover-with-closeable': item.path !== DEFAULT_ROUTE_PATH
                })}
                key={item.path}
                onClick={() => clickHandler(item)}
              >
                <span>{item.name}</span>
                <CloseOutlined
                  className="close"
                  onClick={(e) => closeHandler(e, item)}
                />
              </div>
            ))
          }
        </div>
      </div>

      {
        paginationVisible &&
        <div className="pagination next" onClick={clickNextHandler}>
          <RightOutlined />
        </div>
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    navMenus: state.navMenus
  }
}

const mapDispatchToProps = dispatch => {
  return {
    removeNavMenu: (path) => dispatch(REMOVE_NAV_MENU(path))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Navbar)
