import './style.less'
import { Menu as AntdMenu } from 'antd'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { ADD_NAV_MENU, SET_M_MENU_OPENED } from '@/store/actions'

function toFlatArray (tree, result = [], parentKeys = []) {
  if (!tree) return []
  tree.forEach(e => {
    result.push({
      name: e.label,
      key: e.key,
      parentKeys
    })
    if (e.children) {
      toFlatArray(e.children, result, [...parentKeys, e.key])
    }
  })
  return result
}

function Menu ({ menuData, menuList, isM, addNavMenu, setMMenuOpened }) {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const [openKeys, setOpenKeys] = useState([])

  useEffect(() => {
    if (menuData.length && pathname) {
      const parentKeys = menuList.find(e => e.key === pathname)?.parentKeys || []
      setOpenKeys(parentKeys)
    }
  }, [menuData, menuList, pathname])

  function onClick ({ key }) {
    if (key !== pathname) {
      navigate(key)
      addNavMenu({
        path: key,
        name: menuList.find(e => e.key === key)?.name
      })
    }
    if (isM) {
      setMMenuOpened(false)
    }
  }

  return (
    <>
      <AntdMenu
        theme="dark"
        style={{
          width: 220,
        }}
        mode="inline"
        items={menuData}
        selectedKeys={[pathname]}
        openKeys={openKeys}
        onClick={onClick}
        onOpenChange={openKeys => setOpenKeys(openKeys)}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    isM: state.windowSize === 's',
    menuData: state.menu,
    menuList: toFlatArray(state.menu)
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addNavMenu: (data) => dispatch(ADD_NAV_MENU(data)),
    setMMenuOpened: (opened) => dispatch(SET_M_MENU_OPENED(opened))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu)
